@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  outline: none;
  outline-color: transparent !important;
}

:root {
  --sky-500: #006fee;
  --focus-border: hsl(153.1deg 60.2% 52.7%) !important;
  --sash-hover-size: 2px !important;
  --separator-border: transparent !important;
}

:lang(ja) {
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka,
    "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

:lang(zh) {
  letter-spacing: 1px;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.cursor-blink {
  animation: blink 1s step-start infinite;
}

.tiptap .is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.typography-select :lang(zh) {
  font-family: inherit;
}

.typography-select :lang(ja) {
  font-family: inherit;
}

.resume :lang(ja) {
  font-family: inherit;
}

.resume :lang(zh) {
  font-family: inherit;
}

.resume code {
  font-family: inherit;
}

.dark {
  --nextui-background: 223 49% 8%;
  --nextui-primary-link: 153.1deg 60.2% 52.7%;
  --nextui-primary-button: 151.8deg 47.1% 40.8%;
  --nextui-primary: 153.1deg 60.2% 52.7%;
  --nextui-primary-600: 153deg 59.5% 70%;
  --nextui-primary-500: 153.5deg 61.8% 21.6%;
  --nextui-primary-400: 153.3deg 65.2% 13.5%;
  --nextui-primary-300: 153.8deg 69.6% 9%;
  --nextui-primary-200: 152.5deg 75% 6.3%;
  --cat-card-bg: #151515;
  --cat-builder-bg: #0a101e;
}

.light {
  /* --nextui-background: 210 6% 93%; */
  --nextui-primary-link: 153.4deg 100% 36.7%;
  --nextui-primary-button: 151.8deg 47.1% 40.8%;
  --nextui-primary: 152.9deg 60% 52.9%;
  --nextui-primary-600: 156.5deg 86.5% 26.1%;
  --nextui-primary-500: 155.3deg 78.4% 40%;
  --nextui-primary-400: 151.3deg 66.9% 66.9%;
  --nextui-primary-300: 147.5deg 72% 80.4%;
  --nextui-primary-200: 147.6deg 72.5% 90%;
  --nextui-primary-50: 148deg 100% 98%;
  --cat-card-bg: #fcfcfc;
  --cat-builder-bg: #ecedee;
}

.cat-builder-bg {
  background-color: var(--cat-builder-bg);
}

.cat-bg-card {
  background-color: var(--cat-card-bg);
}

.bg-brand-button {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nextui-primary-button) / var(--tw-bg-opacity));
}

body {
  font-size: 16px;
  /* font-family: "Poppins", sans-serif; */
  /* background-image: url("https://cdn.apifox.com/mirror-www/apidoc/static/light-bg.39b5c750.png");
  background-position: top 0 right 0;
  background-repeat: no-repeat;
  background-size: 100% auto; */
}

.resume,
.resume-builder {
  font-size: var(--resume-font-size);
  color: rgb(15 23 42);
}

.resume * {
  letter-spacing: normal !important;
}

.resume th,
td {
  border: 1px solid rgb(15 23 42);
  padding: 5px;
}

.resume-builder th,
td {
  border: 1px solid rgb(15 23 42);
  padding: 5px;
}

.resume :where(h1) {
  font-size: 2.1428571em;
  line-height: 1.2;
  font-weight: 800;
}

.resume-builder :where(h1) {
  font-size: 2.1428571em;
  line-height: 1.2;
  font-weight: 800;
}

.resume :where(h2) {
  font-size: 1.4285714em;
  line-height: 1.4;
  font-weight: 700;
}

.resume-builder :where(h2) {
  font-size: 1.4285714em;
  line-height: 1.4;
  font-weight: 700;
}

.resume :where(h3) {
  font-size: 1.2857143em;
  font-weight: 600;
  line-height: 1.5555556;
}

.resume-builder :where(h3) {
  font-size: 1.2857143em;
  font-weight: 600;
  line-height: 1.5555556;
}

.resume :where(h4) {
  line-height: 1.4285714;
  font-weight: 600;
}

.resume-builder :where(h4) {
  line-height: 1.4285714;
  font-weight: 600;
}

.resume :where(ul) {
  list-style-type: disc;
  margin-top: 0;
  margin-bottom: 0.5rem;
  padding-left: 1.625em;
}

.resume-builder :where(ul) {
  list-style-type: disc;
  margin-top: 0;
  margin-bottom: 0.5rem;
  padding-left: 1.625em;
}

.resume :where(ol) {
  list-style-type: decimal;
  margin-top: 0;
  margin-bottom: 0.5rem;
  padding-left: 1.625em;
}

.resume-builder :where(ol) {
  list-style-type: decimal;
  margin-top: 0;
  margin-bottom: 0.5rem;
  padding-left: 1.625em;
}

.resume-builder {
  color: var(--nextui-foreground);
}

.resume code {
  padding: 1px 3px;
  display: inline-block;
  border-radius: 4px;
  margin: 2px 0px;
  background-color: rgba(27, 31, 35, 0.05);
  word-break: break-all;
}

.resume-builder code {
  padding: 1px 3px;
  display: inline-block;
  border-radius: 4px;
  margin: 2px 0px;
  background-color: rgba(27, 31, 35, 0.05);
  word-break: break-all;
}

.text-resume-primary {
  color: var(--color-primary);
}

.bg-resume-primary {
  background-color: var(--color-primary);
}

.border-resume-primary {
  border-color: var(--color-primary);
}

.leading-resume {
  line-height: var(--resume-line-height);
}

.gradient-text {
  background: linear-gradient(
    172deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(23, 23, 172, 1) 0%,
    rgba(8, 154, 184, 1) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

*:hover::-webkit-scrollbar-thumb {
  background-color: rgba(100, 100, 100, 0.4);
}

.px-custom {
  padding-left: var(--margin);
  padding-right: var(--margin);
}

.pt-custom {
  padding-top: var(--margin);
}

.pb-custom {
  padding-bottom: var(--margin);
}

.hide-icons .resume-icon {
  display: none;
}

.underline-links a {
  @apply underline underline-offset-2;
}

:root {
  --glow-hue: 222deg;
  --shadow-hue: 180deg;
  --shimmer: 33deg;
  --spring-easing: linear(
    0,
    0.002,
    0.01 0.9%,
    0.038 1.8%,
    0.156,
    0.312 5.8%,
    0.789 11.1%,
    1.015 14.2%,
    1.096,
    1.157,
    1.199,
    1.224 20.3%,
    1.231,
    1.231,
    1.226,
    1.214 24.6%,
    1.176 26.9%,
    1.057 32.6%,
    1.007 35.5%,
    0.984,
    0.968,
    0.956,
    0.949 42%,
    0.946 44.1%,
    0.95 46.5%,
    0.998 57.2%,
    1.007,
    1.011 63.3%,
    1.012 68.3%,
    0.998 84%,
    1
  );
  --spring-duration: 1.33s;
}

@property --shimmer {
  syntax: "<angle>";
  inherits: false;
  initial-value: 33deg;
}

@keyframes shimmer2 {
  0% {
    --shimmer: 0deg;
  }
  100% {
    --shimmer: 360deg;
  }
}

@keyframes shine {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  55% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes text {
  0% {
    background-position: 100% center;
  }
  100% {
    background-position: -100% center;
  }
}

.ai-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 13rem; */
  overflow: hidden;
  /* height: 3rem; */
  background-size: 300% 300%;
  backdrop-filter: blur(1rem);
  border-radius: 5rem;
  transition: 0.5s;
  animation: gradient_301 5s ease infinite;
  border: double 4px transparent;
  background-image: linear-gradient(#ffffff00, #ffffff00),
    linear-gradient(
      137.48deg,
      #ffdb3b 10%,
      #fe53bb 45%,
      #8f51ea 67%,
      #0044ff 87%
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
}

#container-stars {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: 0.5s;
  backdrop-filter: blur(1rem);
  border-radius: 5rem;
}

strong {
  z-index: 2;
  /* font-family: 'Avalors Personal Use';
  font-size: 12px;
  letter-spacing: 5px;
  color: #FFFFFF;
  text-shadow: 0 0 4px white; */
}

#glow {
  position: absolute;
  display: flex;
  width: 12rem;
}

.circle {
  width: 100%;
  height: 30px;
  filter: blur(2rem);
  animation: pulse_3011 4s infinite;
  z-index: -1;
}

.circle:nth-of-type(1) {
  background: rgba(254, 83, 186, 0.636);
}

.circle:nth-of-type(2) {
  background: rgba(142, 81, 234, 0.704);
}

.ai-btn:hover #container-stars {
  z-index: 1;
}

/* .ai-btn:hover {
  transform: scale(1.1);
} */

.ai-btn:active {
  border: double 4px #fe53bb;
  background-origin: border-box;
  background-clip: content-box, border-box;
  animation: none;
}

.ai-btn:active .circle {
  background: #fe53bb;
}

#stars {
  position: relative;
  background: transparent;
  width: 200rem;
  height: 200rem;
}

#stars::after {
  content: "";
  position: absolute;
  top: -10rem;
  left: -100rem;
  width: 100%;
  height: 100%;
  animation: animStarRotate 90s linear infinite;
}

#stars::after {
  background-image: radial-gradient(#ffffff 1px, transparent 1%);
  background-size: 50px 50px;
}

#stars::before {
  content: "";
  position: absolute;
  top: 0;
  left: -50%;
  width: 170%;
  height: 500%;
  animation: animStar 60s linear infinite;
}

#stars::before {
  background-image: radial-gradient(#ffffff 1px, transparent 1%);
  background-size: 50px 50px;
  opacity: 0.5;
}

@keyframes animStar {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-135rem);
  }
}

@keyframes animStarRotate {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0);
  }
}

@keyframes gradient_301 {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes pulse_3011 {
  0% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.ai-textarea {
  outline: none;
  padding: 8px 16px;
  position: relative;
  width: 100%;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  border-image: initial;
  border-radius: 6px;
  font-size: 14px;
  background:
    linear-gradient(white, white) border-box padding-box,
    linear-gradient(to right, rgb(79, 97, 255), rgb(136, 67, 251)) border-box !important;
}

:root {
  --background: 0 0% 100%;
  --foreground: 222.2 84% 4.9%;

  --card: 0 0% 100%;
  --card-foreground: 222.2 84% 4.9%;

  --popover: 0 0% 100%;
  --popover-foreground: 222.2 84% 4.9%;

  --primary: 222.2 47.4% 11.2%;
  --primary-foreground: 210 40% 98%;

  --secondary: 210 40% 96.1%;
  --secondary-foreground: 222.2 47.4% 11.2%;

  --muted: 210 40% 96.1%;
  --muted-foreground: 215.4 16.3% 46.9%;

  --accent: 210 40% 96.1%;
  --accent-foreground: 222.2 47.4% 11.2%;

  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 210 40% 98%;

  --border: 214.3 31.8% 91.4%;
  --input: 214.3 31.8% 91.4%;
  --ring: 222.2 84% 4.9%;

  --radius: 0.5rem;
}

.dark {
  --background: 222.2 84% 4.9%;
  --foreground: 210 40% 98%;

  --card: 222.2 84% 4.9%;
  --card-foreground: 210 40% 98%;

  --popover: 222.2 84% 4.9%;
  --popover-foreground: 210 40% 98%;

  --primary: 210 40% 98%;
  --primary-foreground: 222.2 47.4% 11.2%;

  --secondary: 217.2 32.6% 17.5%;
  --secondary-foreground: 210 40% 98%;

  --muted: 217.2 32.6% 17.5%;
  --muted-foreground: 215 20.2% 65.1%;

  --accent: 217.2 32.6% 17.5%;
  --accent-foreground: 210 40% 98%;

  --destructive: 0 62.8% 30.6%;
  --destructive-foreground: 210 40% 98%;

  --border: 217.2 32.6% 17.5%;
  --input: 217.2 32.6% 17.5%;
  --ring: 212.7 26.8% 83.9%;
}

.qa-markdown-content {
  max-width: none;
  padding: 1.5rem;
  padding-top: 0;
  font-weight: normal;
  color: #464d6a;
}

.qa-markdown-content h1,
.qa-markdown-content h2,
.qa-markdown-content h3,
.qa-markdown-content h4,
.qa-markdown-content h5,
.qa-markdown-content h6 {
  font-weight: 700;
  color: #111827;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.qa-markdown-content h1 {
  font-size: 1.875rem;
  line-height: 2.25rem;
  margin-bottom: 2rem;
}

.qa-markdown-content h2 {
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 1.5rem;
}

.qa-markdown-content h3 {
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-bottom: 1rem;
}

.qa-markdown-content p {
  margin-top: 1rem;
  margin-bottom: 1rem;
  line-height: 1.75;
}

.qa-markdown-content ul,
.qa-markdown-content ol {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  padding-left: 1.5rem;
}

.qa-markdown-content ul {
  list-style-type: disc;
}

.qa-markdown-content ol {
  list-style-type: decimal;
}

.qa-markdown-content li {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-left: 0.5rem;
}

.qa-markdown-content a {
  color: #059669;
  text-decoration: none;
}

.qa-markdown-content a:hover {
  text-decoration: underline;
}

.qa-markdown-content strong {
  font-weight: 700;
  color: #111827;
}

.qa-markdown-content em {
  color: #374151;
  font-style: italic;
}

.qa-markdown-content blockquote {
  border-left: 4px solid #10b981;
  padding-left: 1rem;
  margin: 1.5rem 0;
  font-style: italic;
  color: #4b5563;
}

.qa-markdown-content code {
  background-color: #f3f4f6;
  padding: 0.25rem;
  border-radius: 0.25rem;
}

.qa-markdown-content pre {
  background-color: #f3f4f6;
  padding: 1rem;
  border-radius: 0.5rem;
}

.qa-markdown-content img {
  border-radius: 0.5rem;
  margin: 2rem 0;
}

.qa-markdown-content hr {
  margin: 2rem 0;
  border-color: #e5e7eb;
}

/* Markdown 样式 */
.md-builder {
  @apply text-sm leading-relaxed text-gray-700 dark:text-gray-300;
}

/* 标题样式 */
.md-builder h1 {
  @apply mb-5 mt-6 text-2xl font-bold text-gray-900 dark:text-gray-100;
}

.md-builder h2 {
  @apply mb-3 mt-5 text-xl font-semibold text-gray-800 dark:text-gray-200;
}

.md-builder h3 {
  @apply mb-2 mt-4 text-lg font-medium text-gray-800 dark:text-gray-200;
}

.md-builder h4 {
  @apply mb-2 mt-3 text-base font-medium text-gray-800 dark:text-gray-200;
}

/* 段落和列表样式 */
.md-builder p {
  @apply mb-3 leading-6;
}

.md-builder ul,
.md-builder ol {
  @apply mb-3 space-y-1.5 pl-5;
}

/* 代码块样式 */
.md-builder pre {
  @apply my-4 rounded-lg bg-gray-50 p-3 dark:bg-gray-800;
  width: 100%; /* 固定宽度 */
  overflow-x: auto; /* 添加横向滚动 */
}

.md-builder pre code {
  @apply block whitespace-pre font-mono text-xs text-gray-800 dark:text-gray-200;
  display: inline-block; /* 让内容决定宽度但不换行 */
  white-space: break-spaces;
}

/* 行内代码样式 */
.md-builder code:not(pre code) {
  @apply mx-0.5 rounded border border-gray-200 bg-gray-100 px-1 py-0.5 font-mono text-xs text-gray-800 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-200;
  white-space: pre-wrap;
  white-space: break-spaces;
}

/* 滚动条样式 */
.md-builder pre::-webkit-scrollbar {
  @apply h-1.5;
}

.md-builder pre::-webkit-scrollbar-track {
  @apply bg-transparent;
}

.md-builder pre::-webkit-scrollbar-thumb {
  @apply rounded bg-gray-300 dark:bg-gray-600;
}

/* 代码块标题 */
.md-builder pre[data-filename]::before {
  @apply -mx-3 -mt-3 mb-3 block rounded-t-lg border-b border-gray-200 bg-gray-100 px-3 py-2 font-mono text-xs text-gray-700 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300;
  content: attr(data-filename);
}

/* 代码行号 */
.md-builder pre[data-line-numbers] code {
  @apply relative pl-12;
  counter-reset: line;
}

.md-builder pre[data-line-numbers] code > span {
  @apply block;
  counter-increment: line;
}

.md-builder pre[data-line-numbers] code > span::before {
  @apply absolute left-0 w-8 pr-4 text-right text-gray-400 dark:text-gray-500;
  content: counter(line);
}

.md-builder hr {
  @apply relative my-2 border-0;
  height: 1px;
  background: linear-gradient(
    90deg,
    transparent 0%,
    rgba(0, 0, 0, 0.05) 20%,
    rgba(0, 0, 0, 0.1) 50%,
    rgba(0, 0, 0, 0.05) 80%,
    transparent 100%
  );
}

.dark .md-builder hr {
  background: linear-gradient(
    90deg,
    transparent 0%,
    rgba(255, 255, 255, 0.05) 20%,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(255, 255, 255, 0.05) 80%,
    transparent 100%
  );
}

/* 表格样式 */
.md-builder table {
  @apply my-4 w-full border-collapse overflow-hidden rounded-lg bg-white dark:bg-gray-800;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

.md-builder th {
  @apply bg-gray-100 px-6 py-3 text-left text-sm font-semibold text-gray-700 dark:bg-gray-700 dark:text-gray-200;
  border-bottom: 2px solid #e5e7eb;
}

.md-builder td {
  @apply border-t border-gray-200 px-6 py-4 text-sm text-gray-600 dark:border-gray-600 dark:text-gray-300;
}

.md-builder tr {
  @apply transition-colors hover:bg-gray-100 dark:hover:bg-gray-700;
}

.md-builder tr:nth-child(even) {
  @apply bg-gray-50 dark:bg-gray-700/70;
}

/* 移除之前的边框样式 */
.md-builder th,
.md-builder td {
  border: none;
}

@keyframes scale-check {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.animate-scale-check {
  animation: scale-check 0.3s ease-out forwards;
}
